import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

function EntryPage({ onResults }) {
  const [domain, setDomain] = useState(""); // State to store domain input
  const [error, setError] = useState(""); // State to handle validation errors
  const navigate = useNavigate(); // Use navigate to redirect to the results page

  // Function to check if domain is valid
  const isValidDomain = (domain) => {
    const domainRegex =
      /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
    return domainRegex.test(domain.toLowerCase());
  };

  const handleCheckDNS = () => {
    if (!domain) {
      setError("Domain name is required.");
      setTimeout(() => setError(""), 2000); // Clear error after 2 seconds
    } else if (!isValidDomain(domain.toLowerCase())) {
      // Convert domain to lowercase here
      setError("Please enter a valid domain.");
      setTimeout(() => setError(""), 2000); // Clear error after 2 seconds
    } else {
      setError(""); // Clear error if input is valid
      navigate(`/${domain.toLowerCase()}`); // Always navigate using the lowercase domain
    }
  };

  // Function to handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCheckDNS(); // Trigger DNS check when Enter is pressed
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f4f4f4",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          width: {
            xs: "100%", // Full width for small screens (mobile/tablet)
            sm: "100%", // Full width for small screens (tablet)
            md: "60%", // Boxed layout for larger screens (desktop)
          },
          minHeight: "400px",
          backgroundColor: {
            xs: "transparent", // No background color on mobile/tablet
            sm: "transparent", // No background color on tablet
            md: "#fff", // Boxed layout background for desktop
          },
          padding: {
            xs: "0", // No padding on mobile/tablet
            sm: "0", // No padding on tablet
            md: "40px", // Padding for desktop
          },
          boxShadow: {
            xs: "none", // No shadow on mobile/tablet
            sm: "none", // No shadow on tablet
            md: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Shadow for desktop
          },
          borderRadius: {
            xs: "0", // No border radius on mobile/tablet
            sm: "0", // No border radius on tablet
            md: "8px", // Border radius for desktop
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          paddingY: {
            xs: "20px", // Add padding for top and bottom on mobile/tablet
            sm: "20px",
            md: "60px", // Padding for desktop
          },
        }}
      >
        {/* Left Side: Text */}
        <Box
          sx={{
            flex: 1,
            textAlign: "left",
            paddingRight: {
              md: "40px",
              xs: "0",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center content vertically within the box
            height: "100%", // Make sure this section takes full height
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                xs: "22px",
                sm: "24px",
                md: "26px",
              },
              fontWeight: "700",
              marginBottom: "16px",
              color: "#333",
            }}
          >
            DNS Health Check & Lookup - Free and Easy
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "14px",
                sm: "15px",
                md: "16px",
              },
              color: "#666",
              marginBottom: "16px",
            }}
          >
            Welcome to DNS Peek, your go-to tool for quick and reliable DNS
            health checks, DNS records lookups, and domain blacklist monitoring.
            Whether you're troubleshooting domain issues, verifying DNS
            configurations, or ensuring your domain is clear from mail
            blacklists, our tool provides a fast and accurate analysis—all for
            free! Simply enter your domain in the box to the right, click the
            button, and get a detailed report within seconds.
          </Typography>
        </Box>

        {/* Right Side: Search Field and Buttons */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Make sure this section takes full height
            width: "100%",
          }}
        >
          <TextField
            label="Enter domain name"
            variant="outlined"
            fullWidth
            value={domain}
            onChange={(e) => setDomain(e.target.value)} // Capture domain input
            error={!!error} // Show error state
            helperText={error} // Show error message
            onKeyPress={handleKeyPress} // Handle Enter key press
            sx={{
              marginBottom: "20px",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#283493", // Custom border color when focused
                },
              },
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#283493", // Custom label color when focused
                },
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
          >
            <Button
              variant="contained"
              disableRipple // Removes ripple effect
              disableElevation // Removes elevation (shadow) on click
              onClick={handleCheckDNS} // Navigate to results on click if valid
              sx={{
                backgroundColor: "#283493",
                color: "#fff",
                flex: 1,
                borderRadius: "0", // Make the button square
                "&:hover": {
                  backgroundColor: "#3e52b5", // Hover color for "Check DNS"
                },
              }}
            >
              Check DNS
            </Button>

            <Button
              variant="contained"
              disableRipple // Removes ripple effect
              disableElevation // Removes elevation (shadow) on click
              sx={{
                backgroundColor: "#b00020",
                color: "#fff",
                flex: 1,
                borderRadius: "0", // Make the button square
                "&:hover": {
                  backgroundColor: "#b7384f", // Hover color for "Check Blacklists"
                },
              }}
            >
              Check Blacklists
            </Button>
          </Box>

          {/* Move the "Check your DNS" text and links here */}
          <Box
            sx={{
              textAlign: "left", // Align the text and links to the left
              width: "100%", // Ensure full width for proper left alignment
              marginTop: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                },
                color: "#666",
              }}
            >
              Check your DNS now and keep your domain running smoothly with DNS
              Peek.
            </Typography>

            {/* Links with separator */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Link href="#" underline="hover" color="primary">
                What's New?
              </Link>
              <Typography variant="body1" sx={{ color: "#666" }}>
                |
              </Typography>
              <Link href="#" underline="hover" color="primary">
                About
              </Link>
              <Typography variant="body1" sx={{ color: "#666" }}>
                |
              </Typography>
              <Link href="#" underline="hover" color="primary">
                Contact
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EntryPage;
